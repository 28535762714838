<template>
  <div class="clinic-fees-modal">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Taxas, prazos e outros valores</p>
        <b-button
          @click="$emit('close', false)"
          icon-left="times"
          type="is-text"
        ></b-button>
      </header>
      <section class="modal-card-body">
        <div class="row">
          <div class="col-6">
            <div class="fee-details">
              <b-icon icon="money-bill-alt" type="is-success"></b-icon>
              <p><b>Cartão à vista:</b> {{ feeValues.cash_payment }}</p>
            </div>
            <div class="fee-details">
              <b-icon icon="credit-card" type="is-info"></b-icon>
              <p>
                <b>Cartão 2 à 6x:</b> {{ feeValues.first_installment_option }}
              </p>
            </div>
            <div class="fee-details">
              <b-icon icon="credit-card" type="is-info"></b-icon>
              <p>
                <b>Cartão 7 à 12x:</b> {{ feeValues.second_installment_option }}
              </p>
            </div>
            <div class="fee-details">
              <b-icon icon="qrcode" type="is-dark"></b-icon>
              <p><b>PIX:</b> {{ feeValues.pix_payment }}</p>
            </div>
          </div>
          <div class="col-6">
            <p><b>Recebimento via Cartão:</b> conforme parcelas</p>
            <p><b>Recebimento via Pix:</b> no mesmo dia</p>
            <p><b>Transferência DOC/TED:</b> R$ 3,67</p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    balance_info: Object,
  },
  computed: {
    feeValues() {
      const fee = {};
      const sample_value = 100;

      if (this.balance_info.split_percentage) {
        const split_percentage = this.balance_info.split_percentage;

        const cash_payment = this.formatFeeValue(
          this.calcFeePercentageValue(sample_value, split_percentage, 0.0318),
          this.balance_info.fee
        );

        const first_installment_option = this.formatFeeValue(
          this.calcFeePercentageValue(sample_value, split_percentage, 0.035),
          this.balance_info.fee
        );

        const second_installment_option = this.formatFeeValue(
          this.calcFeePercentageValue(sample_value, split_percentage, 0.0377),
          this.balance_info.fee
        );

        const pix_payment = this.formatFeeValue(
          this.calcFeePercentageValue(sample_value, split_percentage, 0.0088),
          this.balance_info.fee
        );

        fee.pix_payment = pix_payment;
        fee.cash_payment = cash_payment;
        fee.first_installment_option = first_installment_option;
        fee.second_installment_option = second_installment_option;
      }

      return fee;
    },
  },
  data: () => ({}),
  methods: {
    calcFeePercentageValue(sample_value, split_percentage, gatewayFee) {
      return (
        ((sample_value * (1 - gatewayFee) * (1 - split_percentage / 100)) /
          sample_value -
          1) *
        100
      );
    },
    formatFeeValue(percentage_value, fee_value) {
      const currency = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });

      return `${Math.abs(percentage_value)
        .toFixed(2)
        .replace('.', ',')}% + ${currency.format(fee_value)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.fee-details {
  display: flex;
  align-items: center;
  p {
    margin-left: 10px;
  }
}
.modal-card {
  width: 800px;
}
</style>
