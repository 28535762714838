<template>
  <div class="financial-view">
    <app-header icon="coins" title="Financeiro"></app-header>

    <section>
      <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>

      <div class="card">
        <div class="card-header">
          <div class="card-header-title">Fechamento Financeiro</div>
          <div class="card-header-buttons">
            <b-tooltip label="Taxas" position="is-left" class="about-fees">
              <b-button @click="onFeesClick" type="is-text">
                <b-icon
                  custom-class="about-fees__icon"
                  icon="question-circle"
                  size="is-small"
                  type="is-light"
                ></b-icon>
              </b-button>
            </b-tooltip>
          </div>
        </div>
        <div class="card-content">
          <div class="row mt-2" style="align-items: flex-end">
          <div class="col-4">
            <b-field
              label="Período Inicial"
              :type="errors && errors.start_date ? 'is-danger' : ''"
              :message="errors && errors.start_date ? errors.start_date[0] : ''"
            >
              <b-datepicker
                trap-focus
                locale="pt-BR"
                :editable="true"
                icon="calendar"
                horizontal-time-picker
                v-model="start_date"
                placeholder="Início"
                :min-datetime="minDate"
              >
              </b-datepicker>
            </b-field>
          </div>
          <div class="col-4">
            <b-field
              label="Período Final"
              :type="errors && errors.end_date ? 'is-danger' : ''"
              :message="errors && errors.end_date ? errors.end_date[0] : ''"
            >
              <b-datepicker
                trap-focus
                locale="pt-BR"
                :editable="true"
                icon="calendar"
                horizontal-time-picker
                v-model="end_date"
                placeholder="Fim"
                :min-datetime="start_date"
              >
              </b-datepicker>
            </b-field>
          </div>

          <div class="col-4">
            <b-field
              label="Médico/Prof. da saúde"
              :type="errors && errors.selectedDoctor ? 'is-danger' : ''"
              :message="errors && errors.selectedDoctor ? errors.selectedDoctor[0] : ''"
            >
              <b-select
                expanded
                v-model="selectedDoctor"
                placeholder="Selecionar"
                class="month_schedule_view__select-input"
                :disabled="!start_date || !end_date || !doctors.length"
              >
              <option v-for="(doctor, idx) of doctors" :key="idx" :value="doctor.id">
              {{ doctor.name }}
              </option>
              </b-select>
            </b-field>
          </div>

          <div>
            <div class="card-header-buttons">
              <b-button
                @click="loadTransactions"
                :disabled="isLoading || !selectedDoctor || !start_date || !end_date"
                type="is-secondary"
              >
                Gerar Relatório
              </b-button>

              <b-button
                @click="clearData"
                type="is-warning"
                class="ml-2"
              >
                Limpar Dados
              </b-button>
            </div>
         </div>
        </div>
      </div>
    </div>

      <div class="card mt-4" v-if="transactions_data.length">
        <div class="card-content">
          <b-table
            @page-change="onPageChange"
            :per-page="perPage"
            :loading="is_loading_transactions"
            :total="total"
            :data="transactions_data"
            paginated
            backend-pagination
            aria-page-label="Página"
            aria-next-label="Próxima Página"
            aria-current-label="Página Atual"
            aria-previous-label="Página Anterior"
          >

            <b-table-column
              v-if="selectedDoctor === 9999"
              v-slot="props"
              field="doctor_name"
              label="Médico"
            >
              {{ props.row.doctor_name }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="amount"
              label="Paciente"
            >
              {{ props.row.patient_name }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="amount"
              label="Data Consulta"
            >
            {{ props.row.schedule_start | date('DD/MM/YYYY') }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="status"
              label="Data Pagamento"
            >
              {{ props.row.paid_at ? new Date(props.row.paid_at).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }) : 'Aguardando Pagamento' }}
            </b-table-column>
            
            <b-table-column
              v-slot="props"
              field="status"
              label="M. de Pagamento"
            >
            {{ props.row.payment_type_name }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="status"
              label="Plano"
            >
            {{ props.row.health_plan_name ? props.row.health_plan_name : 'Particular' }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="status"
              label="Procedimento"
            >
            {{ props.row.procedure_name }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="status"
              label="Bruto (R$)"
            >
            {{ props.row.gross_value ? formatCurrency(props.row.gross_value) : '0' }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="status"
              label="Repasse (R$)"
            >
            {{ props.row.transfer_value ? formatCurrency(props.row.transfer_value) : '0' }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="status"
              label="Líquido (R$)"
            >
            {{ props.row.net_value ? formatCurrency(props.row.net_value) : '0' }}
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="frown" size="is-large"></b-icon>
                  </p>
                  <p>Nenhum registro encontrado</p>
                </div>
              </section>
            </template>


            <div class="financial-cards">
              <div class="financial-card">
                <div class="financial-card__title">Total Bruto</div>
                <div class="financial-card__value">{{ grossTotal }}</div>
              </div>

              <div class="financial-card">
                <div class="financial-card__title">Total Repasse</div>
                <div class="financial-card__value">{{ transferTotal }}</div>
              </div>

              <div class="financial-card">
                <div class="financial-card__title">Total Líquido</div>
                <div class="financial-card__value">{{ netTotal }}</div>
              </div>
            </div>

            <div class="card-header">
              <PieChart
                :chartData="pieChartData"
                :title="chartTitle"
                width="700px"
                height="500px"
              />
              <BarChart
              :xAxisData="xAxisData"
              :seriesData="seriesData"
              :drilldownData="drilldownData"
            />
            </div>



          <div class="card-header">
            <div class="card-header-title">Transações realizadas</div>
            <div class="is-rigth">
            <div class="card-header-buttons">
              <b-button
                @click="exportToPDF"
                :disabled="isLoading"
                type="is-primary"
              >
                Exportar PDF
              </b-button>
            </div>
              </div>
              <div class="is-rigth">
                <div class="card-header-buttons">
                  <b-button
                    @click="exportToExcel"
                    :disabled="isLoading"
                    type="is-success"
                  >
                    Exportar Planilha
                  </b-button>
                </div>
              </div>
              </div>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ClinicsService from '../services/clinics.service';
import { mapGetters } from 'vuex';
import FinancialService from '../services/financial.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import PieChart from '../components/charts/financial/PieChart.vue';
import BarChart from '../components/charts/financial/BarChart.vue';
import ClinicFees from '../modals/ClinicFees.vue'

export default {
  data: () => ({
    isLoading: false,
    is_loading_transactions: false,
    start_date: null,
    end_date: null,
    selectedDoctor: null,
    doctors: [],
    xAxisData: [],
    seriesData: [],
    drilldownData: [],
    pieChartData: [],
    chartTitle: {
      text: 'Pagamentos',
      subtext: '',
      left: 'center'
    },
    transactions_data: [],
    total: 0,
    page: 1,
    perPage: 15,
    balance_info: {
      fee: null,
      available_amount: null,
      split_percentage: null,
      transferred_amount: null,
      waiting_funds_amount: null,
    },
  }),
  components: {
    PieChart,
    BarChart,
  },
  watch: {
    start_date(val) {
      if (val && this.end_date) {
        this.validateDates();
      }
    },
    end_date(val) {
      if (val && this.start_date) {
        this.validateDates();
      }
    }
  },
  computed: {
    canTransfer() {
      return this.permissions.includes('transfer_financial');
    },
    ...mapGetters(['permissions', 'clinicId']),
    grossTotal() {
      if (!Array.isArray(this.transactions_data)) {
        return 'R$ 0,00'; 
      }
      return this.transactions_data.reduce((total, item) => { 
        const grossValue = item.gross_value || '0';
        return total + parseFloat(grossValue.replace("R$", "").replace(",", "."));
      }, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    transferTotal() {
      if (!Array.isArray(this.transactions_data)) {
        return 'R$ 0,00'; 
      }
      return this.transactions_data.reduce((total, item) => {
        const transferValue = item.transfer_value || '0';
        return total + parseFloat(transferValue.replace("R$", "").replace(",", "."));
      }, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    netTotal() {
      if (!Array.isArray(this.transactions_data)) {
        return 'R$ 0,00'; 
      }
      return this.transactions_data.reduce((total, item) => {
        const netValue = item.net_value || '0';
        return total + parseFloat(netValue.replace("R$", "").replace(",", "."));
      }, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
    
  },
  methods: {
    onFeesClick() {
      this.$buefy.modal.open({
        parent: this,
        component: ClinicFees,
        hasModalCard: true,
        trapFocus: true,
        props: {
          balance_info: this.balance_info,
        },
      });
    },
    validateDates() {
      const startDate = new Date(this.start_date);
      const endDate = new Date(this.end_date);

      // Verifica se a data final é posterior à data inicial
      if (endDate <= startDate) {
        this.$buefy.snackbar.open({
          message: 'A data final deve ser posterior à data inicial!',
          type: 'is-danger'
        });
        return;
      }

      // Calcula a diferença em dias
      const diffDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));

      // Verifica se a diferença é maior que 60 dias
      if (diffDays > 60) {
        this.$buefy.snackbar.open({
          message: 'O período máximo permitido é de 60 dias!',
          type: 'is-danger'
        });
        return;
      }

      // Chama loadDoctors se a validação estiver correta
      this.loadDoctors();
    },
    formatCurrency(value) {
    const number = parseFloat(value).toFixed(2); // Garante duas casas decimais
    return number.replace('.', ','); // Substitui o ponto por vírgula
    },
    formatDate(date) {
      if (!date) return 'Aguardando Pagamento';
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      
      return `${year}-${month}-${day}`;
    },
    formatDateExport(date) {
      if (!date) return '';
      return this.$options.filters.date(date, 'DD/MM/YYYY');
    },
    clearData() {
      this.start_date = null;
      this.end_date = null;
      this.selectedDoctor = null;
      this.transactions_data = []; 
      this.is_loading_transactions = false; 
      this.errors = {}; 
    },
    onPageChange(page) {
      this.page = page;
    },
    loadDoctors() {
      this.isLoading = true;

      const params = {
        clinic_id: this.clinicId,
        start_date: this.formatDate(this.start_date),
        end_date: this.formatDate(this.end_date),
      };

      FinancialService.getDoctors(params)
        .then(({ data }) => {
          this.doctors = [{ id: 9999, name: 'Todos' }, ...data];
        })
        .finally(() => (this.isLoading = false));
    },
    loadBalanceInfo() {
      this.isLoading = true;
      ClinicsService.getBalanceInfo(this.$store.getters.clinicId)
        .then(({ data }) => {
          this.balance_info = { ...data };
        })
        .finally(() => (this.isLoading = false));
    },

    loadTransactions() {
      this.is_loading_transactions = true;
      const params = {
        start_at: this.formatDate(this.start_date),
        end_at: this.formatDate(this.end_date),
        clinic_id: this.$store.getters.clinicId,
        doctor_id: this.selectedDoctor === 9999 ? null : this.selectedDoctor,
      };

      FinancialService.getTransactions(params)
      .then(({ data }) => {
        this.transactions_data = data;

        let totalSchedules = data.length;

        this.total = totalSchedules;

        if(this.total > this.perPage) {
          this.page = this.total / this.perPage;
        }

        let schedulePerPlan = data.filter(item => item.health_plan_name !== null);
        let schedulesPrivate = data.filter(item => item.health_plan_name === null);

        const schedulesPerPlanGrouped = schedulePerPlan.reduce((acc, curr) => {
            acc[curr.health_plan_name] = (acc[curr.health_plan_name] || 0) + 1;
            return acc;
        }, {});

        const xAxisData = ['Total de Atendimentos', 'Atendimentos por Plano', 'Atendimentos Particulares'];

        const seriesData = [
          {
            value: totalSchedules,
            groupId: 'total'
          },
          {
            value: schedulePerPlan.length,
            groupId: 'plano'
          },
          {
            value:  schedulesPrivate.length,
            groupId: 'particular'
          }
        ];

        const drilldownData = [
            {
              dataGroupId: 'plano',
              data: Object.entries(schedulesPerPlanGrouped).map(([plano, count]) => [plano, count])
            }
        ];

        this.xAxisData = xAxisData;
        this.seriesData = seriesData;
        this.drilldownData = drilldownData;

        const schedulesPaid = data.filter(item => item.paid_at !== null);
        const schedulesWaitingPayment = data.filter(item => item.paid_at === null);

        const totalPaid = [
            {
                value: schedulesPaid.length,
                name: 'Atendimentos Pagos'
            },
            {
                value: schedulesWaitingPayment.length,
                name: 'Aguardando Pagamento'
            }
        ];

        this.pieChartData = totalPaid;
        this.is_loading_transactions = false;
      })
      .catch((error) => {
        this.$toast.open({
          message: error.response?.data?.message || 'An error occurred',
          type: 'is-danger',
        });
      })
      .finally(() => {
        this.is_loading_transactions = false;
      });
    },
    formatAmount(value) {
      const amount = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });

      return amount.format(value);
    },
    exportToExcel() {
      // Verifica se deve incluir a coluna "Nome Médico"
      const includeDoctorName = this.selectedDoctor === 9999;

      // Cabeçalhos das colunas
      const headers = [];

      // Adiciona "Nome Médico" no início, se necessário
      if (includeDoctorName) {
        headers.push('Médico');
      }

      // Adiciona os outros cabeçalhos
      headers.push(
        'Paciente', 
        'Data Consulta', 
        'Data Pagamento', 
        'M. de Pagamento', 
        'Plano', 
        'Procedimento', 
        'Bruto (R$)', 
        'Repasse (R$)', 
        'Líquido (R$)'
      );

      // Dados das linhas
      const ws_data = [
        headers,
        ...this.transactions_data.map(transaction => {
          const row = [];

          // Adiciona o nome do médico no início, se necessário
          if (includeDoctorName) {
            row.push(transaction.doctor_name || '');
          }

          // Adiciona os outros dados
          row.push(
            transaction.patient_name || '',
            transaction.schedule_start ? transaction.schedule_start : '',
            transaction.paid_at ? transaction.paid_at : '',
            transaction.payment_type_name || '',
            transaction.health_plan_name ? transaction.health_plan_name : 'Particular',
            transaction.procedure_name || '',
            parseFloat(transaction.gross_value || '0'),
            parseFloat(transaction.transfer_value || '0'),
            parseFloat(transaction.net_value || '0')
          );

          return row;
        }),
        // Linha de totais com um espaço em branco para a nova coluna, se necessário
        Array(headers.length).fill('') // Preenche todas as colunas com valores vazios
        .map((item, index) => index === (includeDoctorName ? 6 : 5) ? 'Totais:' : item) // Define "Totais:" na posição correta
        .map((item, index) => index >= (includeDoctorName ? 7 : 6) ? this.grossTotal.replace('R$', '').trim() : item) // Adiciona o total bruto
        .map((item, index) => index >= (includeDoctorName ? 8 : 7) ? this.transferTotal.replace('R$', '').trim() : item) // Adiciona o total repasse
        .map((item, index) => index >= (includeDoctorName ? 9 : 8) ? this.netTotal.replace('R$', '').trim() : item) // Adiciona o total líquido
      ];

      const ws = XLSX.utils.aoa_to_sheet(ws_data);

      // Define o formato para as colunas de data e converte as datas corretamente
      let dateColumnIndexes = [];

      if (this.selectedDoctor === 9999) {
        dateColumnIndexes.push(2,3) ;  // Índices das colunas de data (baseado em zero)
      } else {
        dateColumnIndexes.push(1,2);  // Índices das colunas de data (baseado em zero)
      }

      dateColumnIndexes.forEach(index => {
        const range = XLSX.utils.decode_range(ws['!ref']);
        for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
          const cellRef = XLSX.utils.encode_cell({ c: index, r: rowNum });
          const cell = ws[cellRef];
          if (cell && cell.v) {
            cell.t = 'd';  // Define o tipo da célula como data
            cell.v = new Date(cell.v); // Converte a string ou número para uma data
          }
        }
      });

      // Aplica a formatação de valores com ",00"
      const valueColumnIndexes = headers
        .map((header, index) => header.includes('R$') ? index : null)
        .filter(index => index !== null);

      valueColumnIndexes.forEach(index => {
        const range = XLSX.utils.decode_range(ws['!ref']);
        for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
          const cellRef = XLSX.utils.encode_cell({ c: index, r: rowNum });
          const cell = ws[cellRef];
          if (cell && typeof cell.v === 'number') {
            cell.z = '#,##0.00';  // Define a formatação personalizada para valores monetários
          }
        }
      });

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Transações');
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'transacoes.xlsx');
    },
    exportToPDF() {
      const doc = new jsPDF('landscape');

      doc.text('Transações Realizadas', 14, 20);

      // Verifica se deve incluir a coluna "Nome Médico"
      const includeDoctorName = this.selectedDoctor === 9999;

      // Cabeçalhos das colunas
      const headers = [];

      // Adiciona "Nome Médico" no início, se necessário
      if (includeDoctorName) {
        headers.push('Médico');
      }

      // Adiciona os outros cabeçalhos
      headers.push(
        'Paciente', 
        'Data Consulta', 
        'Data Pagamento', 
        'M. de Pagamento', 
        'Plano', 
        'Procedimento', 
        'Bruto (R$)', 
        'Repasse (R$)', 
        'Líquido (R$)'
      );

      // Dados das linhas
      const body = this.transactions_data.map(transaction => {
        const row = [];

        // Adiciona o nome do médico no início, se necessário
        if (includeDoctorName) {
          row.push(transaction.doctor_name || '');
        }

        // Adiciona os outros dados
        row.push(
          transaction.patient_name,
          this.formatDateExport(transaction.schedule_start),
          this.formatDateExport(transaction.paid_at),
          transaction.payment_type_name ? transaction.payment_type_name : '',
          transaction.health_plan_name ? transaction.health_plan_name : 'Particular',
          transaction.procedure_name,
          transaction.gross_value ? this.formatCurrency(transaction.gross_value) : '0',
          transaction.transfer_value ? this.formatCurrency(transaction.transfer_value) : '0',
          transaction.net_value ? this.formatCurrency(transaction.net_value) : '0'
        );

        return row;
      });

      // Linha de totais com um espaço em branco para a nova coluna, se necessário
      const totalRow = Array(headers.length).fill(''); // Preenche todas as colunas com valores vazios
      totalRow[headers.length - 4] = 'Totais:';
      totalRow[headers.length - 3] = this.grossTotal.replace('R$', '').trim();
      totalRow[headers.length - 2] = this.transferTotal.replace('R$', '').trim();
      totalRow[headers.length - 1] = this.netTotal.replace('R$', '').trim();

      body.push(totalRow);

      // Ajuste a formatação das colunas
      const valueColumnIndexes = headers.length - 3; // Índice da coluna "Bruto (R$)"
      const columnStyles = {};
      for (let i = valueColumnIndexes; i < headers.length; i++) {
        columnStyles[i] = { fontStyle: 'bold', halign: 'right' };
      }

      doc.autoTable({
        startY: 30,
        head: [headers],
        body: body,
        footStyles: {
          fillColor: [220, 220, 220],
          fontStyle: 'bold', 
        },
        columnStyles: columnStyles,
      });

      doc.save('transacoes.pdf');
    }
  },
  beforeMount() {
  },
  mounted() {
    this.end_date = new Date();
    this.start_date = new Date();
    this.start_date.setDate(this.start_date.getDate() - 1);
    this.selectedDoctor = 9999;

  },
};
</script>

<style lang="scss">
.about-fees {
  cursor: pointer;

  &__icon {
    color: rgba(255, 105, 105, 0.2);
  }
}

.fee_values {
  display: flex;
  flex-direction: column;
}

.financial-cards {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.financial-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  flex: 1;
  margin: 0 10px;
}



.financial-card__title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.financial-card{
  &__value {
    font-size: 1.4rem;
    font-weight: bolder;
  }


}

.financial-card:nth-child(1) {
  color: #47a5d7;
  border-bottom: 5px solid #47a5d7;
}

.financial-card:nth-child(2) {
  color: #ff9800;
  border-bottom: 5px solid #ff9800;
}

.financial-card:nth-child(3) {
  color: #4caf50;
  border-bottom: 5px solid #4caf50;
}


</style>
